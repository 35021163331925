import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "styles/media"

const StyledSection = styled.section`
  margin-bottom: 48px;
  ${media.pc} {
    margin-bottom: 160px;
  }
`

const Box = styled.div`
  margin-bottom: 32px;
  ${media.pc} {
    margin-bottom: 40px;
  }
`

const SectionTitle = styled.h2`
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.color.dark2};
  margin-top: 32px;
  margin-bottom: 16px;
  font-weight: 700;
  ${media.tablet} {
    font-size: ${props => props.theme.font.xl};
    letter-spacing: 0.7px;
    margin-bottom: 20px;
    line-height: normal;
  }
`

const Item = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, fit-content);
  grid-row-gap: 4px;
  margin-bottom: 8px;
  ${media.pc} {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 70px;
    grid-row-gap: 0;
    grid-column-gap: 10px;
  }
  .name,
  .price {
    background-color: #daebfa;
    padding: 16px;
    border-radius: 4px;
    ${media.tablet} {
      padding: 19px 30px;
    }
    & p {
      font-weight: 700;
      ${media.tablet} {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
  .name {
    & p {
      color: #255ed6;
    }
  }
  .price {
    & p {
      color: #fd7468;
      ${media.pc} {
        text-align: center;
      }
    }
  }
`

const PricingTable = () => {
  const { pricing } = useStaticQuery(graphql`
    query Pricing {
      pricing: allDatoCmsPricing {
        edges {
          node {
            category {
              ... on DatoCmsTitle {
                text
                model {
                  name
                }
              }
              ... on DatoCmsItem {
                price
                name
                model {
                  name
                }
              }
            }
          }
        }
      }
    }
  `)
  const { category } = pricing.edges[0].node
  return (
    <StyledSection>
      <Box className="container">
        <SectionTitle />
        <div>
          {category.map(item => {
            const { name } = item.model
            switch (name) {
              case "Item":
                return (
                  <Item key={item.name}>
                    <div className="name">
                      <p>{item.name}</p>
                    </div>
                    <div className="price">
                      <p>{item.price} zł</p>
                    </div>
                  </Item>
                )
              case "Title":
                return <SectionTitle key={item.text}>{item.text}</SectionTitle>
              default:
                return
            }
          })}
        </div>
      </Box>
    </StyledSection>
  )
}

export default PricingTable
