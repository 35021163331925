import React from "react"
import styled from "styled-components"
import { media } from "styles/media"
import Layout from "components/layout/layout"
import PricingTable from "components/cennik/Pricing"
import SEO from "components/seo"

const Title = styled.h1`
  margin: 90px 0 0 !important;
  font-size: ${props => props.theme.font.l};
  line-height: 48px;
  color: ${props => props.theme.color.dark2};
  margin-bottom: 16px;
  font-weight: 700;
  ${media.tablet} {
    margin: 130px auto 0 !important;
    font-size: ${props => props.theme.font.xxl};
    margin-bottom: 20px;
  }
`

const Pricing = () => {
  return (
    <Layout>
      <SEO title="Cennik" />
      <Title className="container">Cennik</Title>
      <PricingTable />
    </Layout>
  )
}

export default Pricing
